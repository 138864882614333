import {
  basePathRewrite,
  checkIsFJFeatureAllowed,
  getClientSettingValue,
  readCookie,
  setClientSettings,
} from '@components/common';
import {
  Regex,
  _CONSTANTS,
  agreementDataForUser,
} from '@components/common/constant';
import { searchRegModalTrackEvent } from '@components/common/event-tracker';
import { Button, FormGroup } from '@license-admin/boldfjcomponents';
import { track } from '@utils/eventTrack';
import { agreementCall, logError, updateRORUserOptins } from 'API/ApiHelpers';
import { isRCPortal, updateUserSettings } from 'helper';
import { fireStepRegistered } from 'helper/accounts';
import LabelControl from 'helper/controls/label';
import { SEARCH_REG_MODAL_CLICK_EVENTS } from 'helper/eventTrackingUtils/constant';
import { useState } from 'react';
import { FormWrap, FormWrapper } from '../styled';

type ErrorMessages = {
  email: string;
  password: string;
  empty_password: string;
};

type UserRegData = {
  email: string;
  password: string;
};
const errorMsg: ErrorMessages = {
  email: 'Please enter your email.',
  empty_password: 'Please enter a password.',
  password: 'Please enter 6 or more characters.',
};

type Props = {
  localization: any;
  config: any;
  claims: any;
  returnUrl: string;
  setPopupState: Function;
  currentUrl?: string;
  isFromWizardPage?: boolean;
  companyExp?: boolean;
};
export default function SoftRegForm(props: Props) {
  const errorDetails = {
    email: '',
    empty_password: '',
    password: '',
  };

  const [validation, setValidation] = useState(errorDetails);
  const [btnLoader, setBtnLoader] = useState(false);

  const [userDetails, setUserDetails] = useState<UserRegData>({
    email: '',
    password: '',
  });

  const isFjFeature = checkIsFJFeatureAllowed();

  const updateUserDetails = (e: any, user?: UserRegData) => {
    let userData = user ? user : userDetails;
    const update = { [e.target.id]: e.target.value };
    setUserDetails({ ...userData, ...update });
  };

  const validateUserDetails = async () => {
    let eventError: any = {};
    Object.entries(userDetails).forEach(([key, value]) => {
      if (key == 'email' && userDetails.email.trim() === '') {
        errorDetails.email = props.localization.error_email;
        eventError['email error'] = true;
      }
      if (
        key == 'email' &&
        !Regex.email.test(userDetails.email.trim()) &&
        userDetails.email.trim() !== ''
      ) {
        errorDetails.email = props.localization.error_valid_email;
        eventError['email error'] = true;
      }
      if (key == 'password' && userDetails.password.trim() === '') {
        errorDetails.password = props.localization.error_empty_password;
        eventError['password error'] = true;
      } else if (
        key == 'password' &&
        typeof value == 'string' &&
        !(value.length >= 6 && value.length <= 16)
      ) {
        errorDetails[key as keyof ErrorMessages] =
          errorMsg[key as keyof ErrorMessages];
        eventError['password error'] = true;
      }

      return {
        isError:
          Object.values(errorDetails).filter(Boolean).length > 0 ? true : false,
        errorDetails: errorDetails,
      };
    });

    return {
      isError:
        Object.values(errorDetails).filter(Boolean).length > 0 ? true : false,
      errorDetails: errorDetails,
    };
  };

  const registerUser = async (e: React.SyntheticEvent) => {
    let userRegistrationProgressObj: any = {};
    setBtnLoader(true);
    e.preventDefault();
    let details = await validateUserDetails();
    if (details.isError) {
      setBtnLoader(false);
      setValidation((prevState) => ({
        ...prevState,
        ...details.errorDetails,
      }));
    } else {
      try {
        userRegistrationProgressObj['userValidationDone'] = {
          status: 'done',
          config: props.config,
        };
        const userDetail = await window.LOGIN.Accounts.registerGuest(
          props.claims.user_uid,
          userDetails.email.trim(),
          userDetails.password,
          '',
          '',
          props.config.productCD,
          'Resumes',
          1,
          '',
          null,
          null,
          null,
          '',
          '',
          window.location.href,
          null
        );
        userRegistrationProgressObj['registerGuest'] = {
          status: 'done',
          userDetail,
        };
        const isSwapedUser =
          userDetail.status === _CONSTANTS.USER_AUTH_USER_SWAP;
        const isNewUser = userDetail.status === _CONSTANTS.USER_AUTH_SUCCESS;
        const lastSearchTerm = getClientSettingValue(
          _CONSTANTS.LAST_SEARCH_TERM
        );
        if (lastSearchTerm) {
          track('identify', {
            lastSearchTerm: lastSearchTerm,
            email: userDetails.email.trim() || '',
          });
        }
        if (isNewUser) {
          fireStepRegistered(userDetails.email.trim());
          userRegistrationProgressObj['fireStepRegistered'] = 'done';
          isFjFeature &&
            (await updateRORUserOptins(userDetail.claims.memberid, {
              jbad: 0,
              inba: 0,
              wnews: 1,
              snof: 0,
              gsts: 0,
              jbsis: 0,
              regs: 1,
            }));
          userRegistrationProgressObj['updateRORUserOptins'] = 'done';
        }
        const agreementData = agreementDataForUser;
        if (userDetail?.userid) {
          await agreementCall(
            userDetail.userid,
            agreementData,
            props.config.portalCD
          );
        }
        userRegistrationProgressObj['agreementCall'] = 'done';

        if (isNewUser || isSwapedUser) {
          if (userDetail?.claims) {
            userDetail.claims.isNewUser = isNewUser;
          }
          setClientSettings({
            key: _CONSTANTS.CLAIMS,
            value: JSON.stringify(userDetail.claims),
          });
          userRegistrationProgressObj['setClientSettings'] = 'done';

          let userObj = {
            userId: isSwapedUser ? userDetail.userid : props.claims.user_uid,
            first_name: '',
            last_name: '',
            email: userDetails.email.trim(),
          };

          await updateUserSettings(userObj, props.config);
          userRegistrationProgressObj['updateUserSettings'] = 'done';
          props.setPopupState(false);

          // * In case of swapped users OR no return url, stay on the same page.
          if (isSwapedUser || !props.returnUrl) {
            userRegistrationProgressObj['reload'] = 'done';
            window.location.reload();
          } else {
            // In case of new user AND return URL, go to url
            userRegistrationProgressObj['hrefUpdate'] = 'done';
            window.location.href = props.returnUrl;
          }
        } else {
          setValidation((prevState) => ({
            ...prevState,
            email: props.localization.error_email_already_register,
          }));
        }
      } catch (err) {
        const userStatusCookie = readCookie(_CONSTANTS.USER_STATUS);
        let userStatus = userStatusCookie && JSON.parse(userStatusCookie);
        if (userStatus?.User?.Role === 1) {
          location.reload();
        } else {
          userRegistrationProgressObj['error'] = JSON.stringify(err);
          logError(
            'RMC RMC_JOBS Client Error on JOBS soft Registration',
            JSON.stringify(userRegistrationProgressObj),
            false
          );
          props.setPopupState(false);
        }
      } finally {
        setBtnLoader(false);
      }
    }
  };

  return (
    <FormWrapper isRCPortal={isRCPortal}>
      <form onSubmit={(e) => registerUser(e)}>
        <LabelControl
          className="soft-reg-label"
          id="email"
          label={props.localization.placeholder_email}
          isRequired={!props.companyExp}
          isRCPortal={isRCPortal}
        ></LabelControl>
        <div
          onClick={() => {
            return searchRegModalTrackEvent({
              action: 'click',
              popup_name: 'registration',
              screen_name: 'job search',
              click_option: SEARCH_REG_MODAL_CLICK_EVENTS.email_prop,
              isFromWizardPage: props.isFromWizardPage,
            });
          }}
        >
          <FormGroup
            value={userDetails.email}
            isRequired={!validation.email}
            errorMsg={validation.email}
            updateFieldData={updateUserDetails}
            maxLength={50}
            isSpacing={true}
            type="text"
            id="email"
            formGrpClass={isRCPortal ? '' : 'fieldGrp emailgrp'}
            errorType="type2"
            isFormSecondary
          />
        </div>
        <LabelControl
          className="soft-reg-label"
          id="password"
          label={
            props.localization.placeholder_create_pass ||
            props.localization.placeholder_pass
          }
          isRequired={!props.companyExp}
          isRCPortal={isRCPortal}
        ></LabelControl>
        <div
          onClick={() =>
            searchRegModalTrackEvent({
              action: 'click',
              popup_name: 'registration',
              screen_name: 'job search',
              click_option: SEARCH_REG_MODAL_CLICK_EVENTS.password_prop,
              isFromWizardPage: props.isFromWizardPage,
            })
          }
        >
          <FormGroup
            id="password"
            type="password"
            formGrpClass={isRCPortal ? '' : 'fieldGrp'}
            errorType="type2"
            hasPwLabel
            updateFieldData={updateUserDetails}
            isRequired={!validation.password}
            errorMsg={validation.password}
            maxLength={16}
            isSpacing={true}
            isFormSecondary
          ></FormGroup>
        </div>
        <FormWrap>
          <p>
            {props.localization.already_have_account}
            <a tabIndex={-1} href={basePathRewrite('/login')}>
              {props.localization.log_in}
            </a>
          </p>
        </FormWrap>
        <div
          onClick={() =>
            searchRegModalTrackEvent({
              action: 'click',
              popup_name: 'registration',
              screen_name: 'job search',
              click_option: SEARCH_REG_MODAL_CLICK_EVENTS.submit,
              isFromWizardPage: props.isFromWizardPage,
            })
          }
        >
          <Button
            ButtonClass="continue-btn"
            text="Continue"
            btnType="submit"
            buttonType="tertiary"
            fullLength={!isRCPortal}
            btnLoader={btnLoader}
            id="soft-reg-continue-btn"
            fontWeight={isRCPortal ? '' : 'bold'}
          />
        </div>
      </form>
    </FormWrapper>
  );
}
