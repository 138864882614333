import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
const blob_path = process.env.NEXT_PUBLIC_CONTENT_URL;
export const SoftRegPopupWrapper = styled.div<{ isRCPortal?: boolean }>`
  // max-width: 97.5rem;
  margin: 0 auto;
  position: relative;
  ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
    SignUpSoftRegPopupStyle &&
    !isRCPortal &&
    css`
      background-color: ${SignUpSoftRegPopupStyle.Colors.softRegPopupWrapper};
      padding: 2.4rem;
      border-radius: 0.4rem;
    `};

  ${({ isRCPortal }) =>
    isRCPortal
      ? Mixin.GridLayout({
          gridDimension: '59.78% 1fr',
          columnGap: '2.2rem',
          rowGap: '0',
        })
      : Mixin.GridLayout({
          gridDimension: '56% 1fr',
          columnGap: '1rem',
          rowGap: '1rem',
        })}
`;

export const InnerWrap = styled.div<{
  leftSection?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
    SignUpSoftRegPopupStyle &&
    !isRCPortal &&
    css`
      background-color: ${SignUpSoftRegPopupStyle.Colors.innerWrap};
      border-radius: 0.4rem;
      text-align: center;
    `}

  padding: ${({ leftSection, isRCPortal }) =>
    isRCPortal && !leftSection
      ? '11rem 4rem 4.65rem'
      : leftSection && isRCPortal
      ? '4rem'
      : leftSection
      ? '4.5rem 0 0'
      : '2.4rem 1.7rem 0'};
  background-color: ${({ leftSection, isRCPortal }) =>
    !leftSection && isRCPortal && '#F8F9FA'};
  border-radius: ${({ leftSection, isRCPortal }) =>
    !leftSection && isRCPortal && '0 0.8rem 0.8rem 0'};
  ${device.mobileLg} {
    padding: 2.8rem;
  }
  .ctrl-lbl {
    font-weight: 400;
  }
`;
export const CloseBtn = styled.button`
  ${Mixin.Position({ value: '0.5rem 1.2rem auto auto' })};
  padding: 0;
  cursor: pointer;
  font-size: 3.5rem;
  z-index: 1;
  line-height: 1;
`;
export const FormWrap = styled.div<{ isRCPortal?: boolean }>`
  padding: ${({ isRCPortal }) => (isRCPortal ? null : '0 4.2rem')};
`;

export const Paragraph = styled.p<{ isRCPortal?: boolean }>`
  ${({ isRCPortal }) =>
    isRCPortal
      ? css`
          font-size: 1.2rem;
          font-weight: 700;
          margin: 0;
        `
      : css`
          font-weight: 500;
          margin-bottom: 2rem;
        `}
`;
export const Desc = styled.p`
  margin: 0 0 0.6rem;
`;

export const FormHeading = styled.h2<{
  isCompanyExp?: boolean;
  isRCPortal?: boolean;
}>`
  font-weight: ${({ isRCPortal }) => !isRCPortal && '600'};
  ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
    SignUpSoftRegPopupStyle &&
    css`
      margin: ${!isRCPortal && '0 0 2.4rem'};
      ${device.mediumScreen} {
        font-size: ${isRCPortal
          ? '4rem'
          : SignUpSoftRegPopupStyle.FontSizes.formHeading};
      }
    `}

  .highlighted-text {
    position: relative;
    text-transform: ${({ isCompanyExp }) => !isCompanyExp && 'lowercase'};
    ${({ isRCPortal }) =>
      !isRCPortal &&
      css`
        &::after {
          content: '';
          background: url(${blob_path}/images/orange-stroke.svg) no-repeat;
          width: 100%;
          background-size: 100% auto;
          background-position: left bottom;
          height: 100%;
          ${Mixin.Position({ value: 'auto auto 0 0 ' })}
        }
      `}
  }
`;

export const FormWrapper = styled.div<{ isRCPortal?: boolean }>`
  padding-top: 1.4rem;
  .soft-reg-label {
    font-weight: ${({ isRCPortal }) => !isRCPortal && '400'};
    ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
      SignUpSoftRegPopupStyle &&
      css`
        color: ${SignUpSoftRegPopupStyle.Colors.label};
        span {
          color: ${!isRCPortal && SignUpSoftRegPopupStyle.Colors.requiredSign};
        }
      `}
  }
  .emailgrp {
    ${device.mobileLg} {
      margin-bottom: 2rem;
    }
  }
  .continue-btn {
    ${device.mobileLg} {
      padding: 0.8rem 1.2rem;
      ${({ theme: { SignUpSoftRegPopupStyle } }) =>
        SignUpSoftRegPopupStyle &&
        css`
          font-size: ${SignUpSoftRegPopupStyle.FontSizes.continueBtn};
        `}
    }
  }
`;
export const FootNote = styled.p<{
  iSBigInterview?: boolean;
  companyExp?: boolean;
  isRCPortal?: boolean;
}>`
  margin: ${({ isRCPortal }) => (isRCPortal ? '2.2rem 0' : ' 1.6rem 0 4rem')};
  ${({ iSBigInterview }) =>
    iSBigInterview &&
    css`
      margin: 1.6rem 0 2.7rem;
      ${({ theme: { SignUpSoftRegPopupStyle } }) =>
        SignUpSoftRegPopupStyle &&
        css`
          color: ${SignUpSoftRegPopupStyle.Colors.footNoteBigInterview};
        `}
    `}
  ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
    SignUpSoftRegPopupStyle &&
    css`
      font-size: ${isRCPortal
        ? '1.2rem'
        : SignUpSoftRegPopupStyle.FontSizes.footNote};
      a {
        font-weight: ${!isRCPortal && '600'};
        &:focus {
          text-decoration: underline;
        }
      }
    `}

  ${device.mobileLg} {
    margin: 2rem 0 2rem;
    ${({ theme: { SignUpSoftRegPopupStyle } }) =>
      SignUpSoftRegPopupStyle &&
      css`
        font-size: ${SignUpSoftRegPopupStyle.FontSizes.footNoteSm};
      `}
    padding: ${({ companyExp, isRCPortal }) =>
      companyExp ? '0.2rem 0.9rem' : isRCPortal ? null : ' 0 3.6rem'};
  }
`;

export const ImgSecWrap = styled.div`
  margin-top: 1.1rem;
`;

export const SubHeading = styled.p`
  margin: 1.3rem 0 0.5rem;
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      color: ${SignUpSoftRegPopupStyle.Colors.subHeading};
    `}
`;

export const SecDesc = styled.p<{ companyExp?: boolean; isRCPortal?: boolean }>`
  ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
    SignUpSoftRegPopupStyle &&
    css`
      font-size: ${SignUpSoftRegPopupStyle.FontSizes.SecDescBigInterview};
    `}
  margin: 0 0 2.4rem;
  ${device.mobileLg} {
    ${({ companyExp }) =>
      companyExp &&
      css`
        padding: 0 1.6rem;
      `}
  }
`;

export const BtmImgWrap = styled.div<{ companyExp?: boolean }>`
  position: relative;
  padding-top: ${({ companyExp }) => (companyExp ? '2.6rem' : ' 1.2rem')};
  img {
    max-width: 25.2rem;
  }
  &::before {
    content: '';
    width: 10.7rem;
    height: 0.05rem;
    display: block;
    ${Mixin.Position({ value: '0 auto auto 50% ' })};
    ${({ theme: { SignUpSoftRegPopupStyle } }) =>
      SignUpSoftRegPopupStyle &&
      css`
        background-color: ${SignUpSoftRegPopupStyle.Colors.btmImgWrap};
      `};
    transform: translateX(-50%);
  }
`;

export const DrawerInnerWrap = styled.div<{ isRCPortal?: boolean }>`
  text-align: center;

  ${({ theme: { SignUpSoftRegPopupStyle }, isRCPortal }) =>
    SignUpSoftRegPopupStyle &&
    !isRCPortal &&
    css`
      margin: 0 -1rem;
      color: ${SignUpSoftRegPopupStyle.Colors.drawerInnerWrap};
    `};
  &::-webkit-scrollbar {
    display: none;
  }
  .ctrl-lbl {
    font-weight: 400;
    font-size: 1.2rem;
  }
  ${({ isRCPortal }) =>
    isRCPortal &&
    css`
      padding: 2rem;
    `}
`;

export const Title = styled.span`
  font-weight: 600;
  display: inline-block;
  margin: 0 0 0.7rem;
  span {
    text-transform: lowercase;
  }
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      color: ${SignUpSoftRegPopupStyle.Colors.title};
      font-size: ${SignUpSoftRegPopupStyle.FontSizes.title};
    `};
`;

export const Heading = styled.h2<{
  companyExp?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      font-size: ${SignUpSoftRegPopupStyle.FontSizes.heading};
    `};
  font-weight: 600;
  margin: ${({ companyExp }) =>
    companyExp ? '2rem 0 1.5rem 0' : '0 0 1.5rem'};
  .highlighted-text {
    position: relative;
    text-transform: ${({ companyExp }) => !companyExp && 'lowercase'};
    ${({ isRCPortal }) =>
      !isRCPortal &&
      css`
        &::after {
          content: '';
          background: url(${blob_path}/images/orange-stroke.svg) no-repeat;
          width: 100%;
          background-size: 100% auto;
          background-position: left bottom;
          height: 100%;
          ${Mixin.Position({ value: 'auto auto -0.5rem 0' })}
        }
      `}
  }
`;

export const ImgSitejabber = styled.img`
  // object-fit: contain;
`;

export const BorderSpan = styled.span`
  width: 10.7rem;
  height: 0.05rem;
  display: block;
  margin: 1.1rem auto 2rem;
  background: #8e8d8f;
`;

export const HighlightText = styled.span``;
export const BtmImgWrapHead = styled.span`
  display: block;
  font-size: 1.4rem;
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      color: ${SignUpSoftRegPopupStyle.Colors.BtmImgWrapHead};
    `};
  line-height: 1.4;
  font-weight: 500;
  padding: 1rem 0 0.5rem;
  ${device.mobileLg} {
    padding: 2.3rem 4.4rem 0.3rem;
  }
`;
export const BigReferedTxt = styled.span`
  font-size: 1.2rem;
  line-height: 1.2;
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      color: ${SignUpSoftRegPopupStyle.Colors.BigReferedTxt};
      ${device.mobileLg} {
        color: ${SignUpSoftRegPopupStyle.Colors.BigReferedTxtSm};
      }
    `};
  padding-left: 3.4rem;
  grid-column: span 2;
  ${device.mobileLg} {
    margin-top: 3.2rem;
    padding: 0;
    font-size: 1rem;
    line-height: 1;
  }
`;
export const SecDescBigInterview = styled.p`
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      font-size: ${SignUpSoftRegPopupStyle.FontSizes.SecDescBigInterview};
    `}
  max-width:35.9rem;
  min-height: 5rem;
  margin: 0 auto 1.9rem;
  ${device.mobileLg} {
    margin: 0 1.9rem 1.3rem;
    line-height: 1.2;
  }
`;
export const SubHeadingBigInterview = styled.strong`
  display: block;
  margin: 1rem 0 0.5rem;
  ${({ theme: { SignUpSoftRegPopupStyle } }) =>
    SignUpSoftRegPopupStyle &&
    css`
      color: ${SignUpSoftRegPopupStyle.Colors.subHeading};
    `}
  ${device.mobileLg} {
    font-size: 1.6rem;
  }
`;

// export const StarIcon = styled.span`
//   height: 2.4rem;
//   width: 2.4rem;
//   display: block;
//   padding-top: 0.2rem;
//   text-align: center;
//   ${({ theme: { SignUpSoftRegPopupStyle } }) =>
//     SignUpSoftRegPopupStyle &&
//     css`
//       color: ${SignUpSoftRegPopupStyle.Colors.StarIcon};
//       font-size: ${SignUpSoftRegPopupStyle.FontSizes.StarIcon};
//       border: 0.2rem solid ${SignUpSoftRegPopupStyle.Colors.StarIcon};
//     `}
// `;
export const RCHeading = styled.h3`
  margin: 0 0 2.4rem;
`;
