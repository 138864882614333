import {
  basePathRewrite,
  createCookie,
  displayThousandsOnly,
  getNumFormatter,
  isUpdateLocalJobCount,
  localJobCountUpdate,
} from '@components/common';
import { searchRegModalTrackEvent } from '@components/common/event-tracker';
import { IJobCount } from '@components/shared.types';
import { Popup } from '@license-admin/boldfjcomponents';
import { getCompaniesHiringCount } from 'API/jobs';
import { isPPPageLink, isRCPortal } from 'helper';
import { createGuestUsingAccounts } from 'helper/accounts';
import { SEARCH_REG_MODAL_CLICK_EVENTS } from 'helper/eventTrackingUtils/constant';
import { useEffect, useRef, useState } from 'react';
import SoftRegForm from './softRegForm';
import {
  BtmImgWrap,
  CloseBtn,
  Desc,
  FootNote,
  FormHeading,
  FormWrap,
  ImgSecWrap,
  InnerWrap,
  Paragraph,
  RCHeading,
  SecDesc,
  SoftRegPopupWrapper,
  SubHeading,
} from './styled';

type LocalizationKeys =
  | 'form_heading'
  | 'form_heading2'
  | 'form_heading3'
  | 'placeholder_email'
  | 'placeholder_pass'
  | 'placeholder_creact_pass'
  | 'sign_up_today'
  | 'error_valid_email'
  | 'error_pasword'
  | 'error_email_already_register'
  | 'dont_miss_oppurtunity_heading'
  | 'big_interview_heading'
  | 'big_interview_started_desc_enter_email'
  | 'get_started_desc'
  | 'no_more_hopping'
  | 'no_ads_no_scam'
  | 'no_ordinary_job_site'
  | 'save_time'
  | 'big_Interview_training_tool'
  | 'terms_and_condition_Big_Interview'
  | 'ai_powered_feedback'
  | 'big_Interview_hired'
  | 'big_refered_txt'
  | 'terms_and_condition'
  | 'already_account'
  | 'error_email'
  | 'best_remote_job_listing'
  | 'soft_reg_desc'
  | 'log_in'
  | 'soft_reg_mobile_title'
  | 'm_soft_reg_subHeading'
  | 'm_soft_reg_heading'
  | 'get_started_desc_enter_email'
  | 'error_empty_password'
  | 'team_spends_big_interview'
  | 'already_have_account';

type Localization = Record<LocalizationKeys, string>;

type Props = {
  localization: Localization;
  config: any;
  setPopupState: Function;
  returnUrl: string;
  mobileView?: boolean;
  onlyForm?: boolean;
  softRegRef?: any;
  closeModal?: any;
  hideTitle?: boolean;
  hiringCount?: IJobCount;
  heading?: string;
  subheading?: string;
  // 0 is for wizard and 1 is for baseline
  type?: number;
  description?: string;
  isCloseAllowed?: boolean;
  currentUrl?: string;
  companiesHiringCount?: IJobCount;
  isSoftRegModal?: boolean;
  showSoftRegPopUp?: boolean;
  isFromWizardPage?: boolean;
  companyRegModalExpVariantB?: boolean;
  companyRegModalExpVariantC?: boolean;
};

type claims = {
  user_uid: string;
  user_type_cd: string;
  role: string;
  portal_cd: string;
  sessionid: string;
  proxy_user: string;
};

export default function SoftRegPopup(props: Props) {
  const numFormat = getNumFormatter();
  const jobsCount = props.hiringCount?.jobsCount ?? 0;
  const formattedCount = numFormat.format(displayThousandsOnly(jobsCount));
  const softPopupRef = useRef<HTMLDivElement | null>(null);

  const { heading, subheading } = props;
  const softRegRef = useRef(null);
  const [claims, setClaims] = useState<claims>({
    user_uid: '',
    user_type_cd: '',
    role: '',
    portal_cd: '',
    sessionid: '',
    proxy_user: '',
  });

  const handleCloseModal = () => {
    props.isCloseAllowed && props.setPopupState(false);
  };
  const createGuestUser = async () => {
    if (window && window.LOGIN) {
      const CompaniesHiringCount = await getCompaniesHiringCount();

      if (isUpdateLocalJobCount()) {
        localJobCountUpdate(CompaniesHiringCount);
      }

      const guestUser = await createGuestUsingAccounts(props.config);
      setClaims(guestUser.claims);
    }
  };

  useEffect(() => {
    createCookie('softRegPopupDisplayed', 'true');
    const script = document.createElement('script');
    script.src =
      process.env.NODE_ENV === 'development'
        ? `${process.env.NEXT_PUBLIC_ROOT_URL}/signin/scripts/app/login.min.js`
        : `/signin/scripts/app/login.min.js`;
    script.async = true;

    // Define a function to be called after the script loads
    script.onload = () => {
      createGuestUser();
    };

    // Append the script to the document's body
    document.body.appendChild(script);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      if (document && document.body) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (props.isSoftRegModal || props.showSoftRegPopUp || props.setPopupState) {
      setTimeout(() => {
        if (softPopupRef.current) {
          softPopupRef.current.focus();
        }
        let lastItem = document.getElementById('log-in-link');
        const handleKeyDown = (e: any) => {
          if (e.key === 'Tab') {
            if (document.activeElement === lastItem && softPopupRef) {
              softPopupRef.current && softPopupRef.current.focus();
            }
          }
        };
        document.addEventListener('keydown', handleKeyDown);
      }, 10);
      searchRegModalTrackEvent({
        action: 'viewed',
        popup_name: 'registration',
        screen_name: 'job search',
        isFromWizardPage: props.isFromWizardPage,
      });
    }
  }, [props.isSoftRegModal, props.showSoftRegPopUp, props.setPopupState]);

  return (
    <div>
      {/* {props.mobileView ? (
        <DrawerInnerWrap ref={softRegRef} isRCPortal={isRCPortal}>
          {!props.hideTitle && (
            <Title
              dangerouslySetInnerHTML={{
                __html:
                  heading || props.localization.dont_miss_oppurtunity_heading,
              }}
            />
          )}
          <Heading
            companyExp={
              props.companyRegModalExpVariantB ||
              props.companyRegModalExpVariantC
            }
            dangerouslySetInnerHTML={{
              __html: subheading || props.localization.get_started_desc,
            }}
            isRCPortal={isRCPortal}
          />
          {props.description && (
            <Desc>
              {props.description ||
                props.localization.get_started_desc_enter_email}
            </Desc>
          )}
          <SoftRegForm
            localization={props.localization}
            config={props.config}
            claims={claims}
            returnUrl={props.returnUrl}
            setPopupState={props.setPopupState}
            currentUrl={props?.currentUrl || ''}
            isFromWizardPage={props.isFromWizardPage}
            companyExp={
              props.companyRegModalExpVariantB ||
              props.companyRegModalExpVariantC
            }
          />
          <FootNote
            companyExp={
              props.companyRegModalExpVariantB ||
              props.companyRegModalExpVariantC
            }
            dangerouslySetInnerHTML={{
              __html: props.localization.terms_and_condition,
            }}
            isRCPortal={isRCPortal}
          />
          {!props.onlyForm ? (
            <>
              <ImgSecWrap>
                <img
                  alt="job-listing-image"
                  src={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/Jobs-Listing.png`}
                />
                <SubHeading>
                  <strong>{props.localization.best_remote_job_listing}</strong>
                </SubHeading>
                <SecDesc
                  companyExp={props.companyRegModalExpVariantB}
                  isRCPortal={isRCPortal}
                >
                  {props.localization.no_ads_no_scam.replace(
                    '{0}',
                    formattedCount
                  )}
                </SecDesc>
              </ImgSecWrap>
              <div>
                <img
                  alt="save-time-image"
                  src={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/Save-Time.png`}
                />
                <SubHeading>
                  <strong>
                    {props.companyRegModalExpVariantB
                      ? 'Save Time with Job Alerts'
                      : props.localization.save_time}
                  </strong>
                </SubHeading>
                <SecDesc
                  companyExp={props.companyRegModalExpVariantB}
                  isRCPortal={isRCPortal}
                >
                  {props.companyRegModalExpVariantB
                    ? 'Gain an advantage with personalized job alerts. Go straight from job listings to applications and land your remote job. '
                    : props.localization.no_more_hopping}
                </SecDesc>
              </div>
            </>
          ) : null}

          {props.companyRegModalExpVariantB ||
          props.companyRegModalExpVariantC ? (
            <BorderSpan />
          ) : (
            ''
          )}
          <ImgSitejabber
            src={
              props.companyRegModalExpVariantB ||
              props.companyRegModalExpVariantC
                ? `${process.env.NEXT_PUBLIC_CONTENT_URL}/images/site-jabber-image-new.svg`
                : `${process.env.NEXT_PUBLIC_CONTENT_URL}/images/site-jabber-image.svg`
            }
            width={
              props.companyRegModalExpVariantB ||
              props.companyRegModalExpVariantC
                ? 219
                : 252
            }
            height={57}
            // style={{ aspectRatio: 252 / 57 }}
          />
        </DrawerInnerWrap>
      ) : (
        
      )} */}
      <Popup
        modalSize="lgPlus"
        hideCloseModal
        closeModal={handleCloseModal}
        popupId="soft-reg-popup-content"
        heading="login"
        popUpRef={softPopupRef}
        isPrimaryModal
      >
        <SoftRegPopupWrapper isRCPortal={isRCPortal}>
          {props.mobileView && (
            <CloseBtn onClick={() => handleCloseModal()}>&times;</CloseBtn>
          )}

          <InnerWrap leftSection isRCPortal={isRCPortal}>
            <FormHeading
              isCompanyExp={
                props.companyRegModalExpVariantB ||
                props.companyRegModalExpVariantC
              }
              dangerouslySetInnerHTML={{
                __html:
                  heading || props.localization.dont_miss_oppurtunity_heading,
              }}
              isRCPortal={isRCPortal}
            />
            <FormWrap isRCPortal={isRCPortal}>
              <Desc>
                {subheading || props.localization.get_started_desc_enter_email}
              </Desc>
              <SoftRegForm
                localization={props.localization}
                config={props.config}
                claims={claims}
                returnUrl={props.returnUrl}
                setPopupState={props.setPopupState}
                currentUrl={props.currentUrl}
                isFromWizardPage={props.isFromWizardPage}
                companyExp={
                  props.companyRegModalExpVariantB ||
                  props.companyRegModalExpVariantC
                }
              />

              <FootNote
                dangerouslySetInnerHTML={{
                  __html:
                    props.companyRegModalExpVariantB ||
                    props.companyRegModalExpVariantC
                      ? `By clicking “Continue”, you agree to our <a id='terms-and-conditions' target='blank' href='/tos/'>Terms of Use </a> and <a id='privacy-policy' target='_blank' href=${isPPPageLink}>Privacy Policy</a>, and to receive email updates. You can unsubscribe anytime.`
                      : props.localization.terms_and_condition,
                }}
                isRCPortal={isRCPortal}
              />
              <Paragraph isRCPortal={isRCPortal}>
                {props.localization.already_account}{' '}
                <a
                  href={basePathRewrite('/login')}
                  id="log-in-link"
                  onClick={() =>
                    searchRegModalTrackEvent({
                      action: 'click',
                      popup_name: 'registration',
                      screen_name: 'job search',
                      click_option: SEARCH_REG_MODAL_CLICK_EVENTS.log_in,
                      isFromWizardPage: props.isFromWizardPage,
                    })
                  }
                >
                  Log In
                </a>
              </Paragraph>
            </FormWrap>
          </InnerWrap>
          <InnerWrap isRCPortal={isRCPortal}>
            {isRCPortal ? (
              <RCHeading>{props.localization.no_ordinary_job_site}</RCHeading>
            ) : (
              <h2>{props.localization.no_ordinary_job_site}</h2>
            )}
            <div>
              <ImgSecWrap>
                {/* {isRCPortal ? (
                  <StarIcon className="fa-regular fa-star" />
                ) : (
                  <img
                    alt="job-listing-image"
                    src={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/Jobs-Listing.png`}
                  />
                )} */}
                <SubHeading>
                  <strong>{props.localization.best_remote_job_listing}</strong>
                </SubHeading>
                <SecDesc isRCPortal={isRCPortal}>
                  {props.localization.no_ads_no_scam.replace(
                    '{0}',
                    formattedCount
                  )}
                </SecDesc>
              </ImgSecWrap>
              {/* {isRCPortal ? (
                <StarIcon className="fa-regular fa-star" />
              ) : (
                <img
                  alt="save-time-image"
                  src={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/Save-Time.png`}
                />
              )} */}

              <SubHeading>
                <strong>
                  {props.companyRegModalExpVariantB ||
                  props.companyRegModalExpVariantC
                    ? 'Save Time with Personalized Job Alerts'
                    : props.localization.save_time}
                </strong>
              </SubHeading>
              <SecDesc isRCPortal={isRCPortal}>
                {props.companyRegModalExpVariantB
                  ? 'Gain an advantage with  job alerts. Go straight from job listings to applications and land your remote job. '
                  : props.companyRegModalExpVariantC
                  ? 'Gain an advantage with personalized job alerts. Go straight from job listings to applications and land your remote job. '
                  : props.localization.no_more_hopping}
              </SecDesc>
            </div>
            {!isRCPortal && (
              <BtmImgWrap
                companyExp={
                  props.companyRegModalExpVariantB ||
                  props.companyRegModalExpVariantC
                }
              >
                <img
                  alt="site-jabber"
                  src={
                    props.companyRegModalExpVariantB ||
                    props.companyRegModalExpVariantC
                      ? `${process.env.NEXT_PUBLIC_CONTENT_URL}/images/site-jabber-image-new.svg`
                      : `${process.env.NEXT_PUBLIC_CONTENT_URL}/images/site-jabber-image.svg`
                  }
                  width={277}
                  height={82}
                  // style={{ aspectRatio: 277 / 82 }}
                />
              </BtmImgWrap>
            )}
          </InnerWrap>
        </SoftRegPopupWrapper>
      </Popup>
    </div>
  );
}
