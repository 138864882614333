import { AstrixWrap, LabelComponent } from './styled';
type LabelProps = {
  id?: string;
  label: string;
  className?: string;
  isRequired?: boolean;
  onClick?: any;
  reportReason?: Function;
  isLabelOverlap?: boolean;
  isLabelError?: boolean | undefined;
  isRCPortal?: boolean;
};

export default function LabelControl(props: LabelProps) {
  const setReportReason = () => {
    if (props.reportReason) {
      props.reportReason(props.label);
    }
  };

  return (
    <LabelComponent
      id={`label-${props.id}`}
      htmlFor={props.id}
      className={props.className}
      onClick={setReportReason}
      isLabelOverlap={props.isLabelOverlap}
      isLabelError={props.isLabelError}
      isRCPortal={props.isRCPortal}
    >
      {props.label}{' '}
      {props.isRequired && (
        <AstrixWrap isRCPortal={props.isRCPortal}>*</AstrixWrap>
      )}
    </LabelComponent>
  );
}
