import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const LabelComponent = styled.label<{
  isLabelOverlap?: boolean;
  isLabelError?: boolean | undefined;
  isRCPortal?: boolean
}>`
  font-weight: ${({ isRCPortal }) => isRCPortal ? '700' : '600'};
  font-size: ${({ isRCPortal }) => isRCPortal && '1.4rem'};
  margin-bottom: ${({ isRCPortal }) => isRCPortal ? '0.4rem' : '0.8rem'};

  ${Mixin.HorizontalCenter({ justify: 'flex-start' })};
  ${({ isLabelOverlap }) =>
    isLabelOverlap &&
    css`
      ${Mixin.Position({ value: '-0.7rem auto auto 1.2rem' })}
      line-height: 1.2rem;
      z-index: 1;
      ${({ theme: { LabelStyle } }) =>
        LabelStyle &&
        css`
          background-color: ${LabelStyle.BgColor.bggray};
        `};
    `}
  ${({ theme: { LabelStyle }, isLabelError }) =>
    LabelStyle &&
    css`
      font-weight: ${LabelStyle.FontWeight.semibold};
      color: ${isLabelError && LabelStyle.Colors.ErrorLabel};
    `};
`;

export const AstrixWrap = styled.span<{ isRCPortal?: boolean }>`
  ${({ theme: { LabelStyle }, isRCPortal }) =>
    LabelStyle &&
    css`
      color: ${!isRCPortal && LabelStyle.Colors.Astrix};
      font-size: ${isRCPortal && '1.2rem'};
      font-weight: ${isRCPortal && '400'};
      margin-left: 0.4rem;
    `}
`;
